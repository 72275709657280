<template>
  <div class="join-us">
    <div class="join-en">JOIN US</div>
    <div class="join-zh">加入我们</div>
    <p class="post" @click="join">
      查看所有职位空缺
    </p>
  </div>
</template>

<script>
export default {
  methods: {
    join() {
      this.$router.push({
        path: '../career'
      })
    }
  }
}
</script>

<style scoped>
.join-us {
  margin-top: 20px;
  width: 100%;
  height: 21rem;
  background: url('../../assets/image/mobile/index/zp.png') no-repeat;
  background-size: 100% 100%;
  font-weight: 700;
  color: #fdfefa;
  text-align: center;
  background-color: #f5f5f5;
}
.join-en {
  padding-top: 8.5rem;
  font-size: 2.6rem;
  line-height: 2.9375rem;
  opacity: 0.76;
  color: #fdfefa;
  font-family: Poppons !important;
}
.join-zh {
  margin-top: .6rem;
  color: #fdfefa;
  font-family: MicrosoftYaHei-Bold !important;
  font-weight: bold;
  font-size: 1.2rem;
}
.join-us .post {
  width: 10.375rem;
  height: 2.25rem;
  background: #e60012;
  border-radius: 1.125rem;
  font-size: 1rem;
  line-height: 2.25rem;
  margin: 2.4rem auto;
  cursor: pointer;
  font-weight: 400;
  color: #fdfefa;
}
</style>
